import React from 'react';
import ReactDOM from 'react-dom/client';
import './Sides.css';
import Document from './Document';
import {documents} from './todisplay.js';

function ReferenceSide(props) {

    function renderDocs() {
        return (documents.map((doc, index) => {
            return (
                <Document img={doc.img} doc={doc}/>
            )
        }));
    }

    return (
        <div id="ReferenceSide">
            {renderDocs()}  
        </div>
    );
}

export default ReferenceSide;