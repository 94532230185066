import React from 'react';
import ReactDOM from 'react-dom/client';
import './Article.css';

function Article(props) {

    function renderParagraphs(str) {
        return (
            <div className="ArticleText" dangerouslySetInnerHTML={{ __html: str }} />
        )
    }

    return (
        <div className='Article'>
            <div className='ArticleImg'><img src={props.post.img} alt="Błąd ładowania obrazu." /></div>
            <div className="ArticleTextSection">
                <div><p className='ArticleTittle'>{props.post.tittle}</p></div>
                <div>{renderParagraphs(props.post.text)}</div>
            </div>
        </div>
    )
}

export default Article;

//                <div><p className="ArticleText">{props.post.text}</p></div>

//function renderParagraphs(strArr){
//return(strArr.map(str =>{
// return(
// <p className="ArticleText">{str}</p>
//<div className="ArticleText" dangerouslySetInnerHTML={{ __html: str }} />
// )
// }))
//}