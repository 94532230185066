import React, { useState } from 'react';
import './Document.css';
import ZoomImage from './ZoomImage';

function Document(props) {
    const [isHovered, setIsHovered] = useState(false);
    const [isZoomed, setIsZoomed] = useState(false);
    const [isClosing, setIsClosing] = useState(false); // Nowy stan do animacji zamykania

    const handleZoomToggle = () => {
        if (isZoomed) {
            setIsClosing(true); // Aktywuje animację zamykania
            setTimeout(() => {
                setIsZoomed(false);
                setIsClosing(false); // Po zakończeniu animacji usuwa klasę
            }, 800); // Czas trwania animacji fadeOut
        } else {
            setIsZoomed(true);
        }
    };

    return (
        <div
            className={`Document ${(isHovered && !isZoomed) ? 'DocumentHovered' : ''}`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={handleZoomToggle}
        >
            <div className={`${isZoomed ? 'imgBoxZoomed' : 'imgBox'} ${isClosing ? 'imgBoxClosing' : ''}`}>
                <img className={`${isZoomed ? 'documentImageZoomed' : 'documentImage'}`} src={props.img} alt={props.doc.title} />
            </div>

        </div>
    );
}

export default Document;
