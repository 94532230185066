import React from 'react';
import ReactDOM from 'react-dom/client';
import './Sides.css';
import img1 from './Images/SCHEIco.png';
import img2 from './Images/fuelIco.png';
import img3 from './Images/CarbonIco2.png';

function MainSide(props) {

    return (
        <div id="MainSide">
            <div class="MainSideText">
                <div>Jestem specjalistą z zakresu efektywności energetycznej budynków oraz procesów termodynamicznych. Zajmuje się oceną oraz optymalizacją zużycia energii w analizowanych obiektach. Pomagam moim klientom zwiększyć efektywność energetyczną, redukować koszty energii oraz minimalizować szkodliwy wpływ budynków i instalacji na środowisko naturalne.</div>
                <br></br>
                <div>Oferuje kompleksowe usługi w zakresie:</div>
                <ul>
                    <li>Przeprowadzania audytów energetycznych i remontowych.</li>
                    <li>Przeprowadzania audytów efektywności energetycznej.</li>
                    <li>Przeprowadzania audytów oświetlenia.</li>
                    <li>Sporządzania Świadectw Charakterystyki Energetycznej budynków istniejących.</li>
                    <li>Sporządzania Projektowych Charakterystyk Energetycznych budynków na etapie projektu.</li>
                    <li>Doboru mocy grzejników i urządzeń grzewczych.</li>
                    <li>Doboru mocy instalacji OZE.</li>
                    <li>Wyznaczania współczynników korekcyjnych LAF.</li>
                    <li>Wykonywania pomiarów termowizyjnych.</li>
                </ul>
                <div>Dzięki mojemu doświadczeniu i nowoczesnym metodą analizy danych, dostarczam rzetelne opracowania, które pomagają uzyskać realne oszczędności i zwiększyć efektywność zarządzania energią.</div>
                <br></br>
                <div>Serdecznie zapraszam Państwa do kontaktu.</div>
            </div>
            <div class="MainSideInfoBox">
                <div class="MainSideBox">
                    <div class="MainSideBoxImg"><img src={img1} alt="bład ładowania..."/></div>
                    <div class="MainSideBoxText">
                        <b>Ponad 215</b>
                        <p>wykonanych Audytów Energetycznych</p></div>
                </div>
                <div class="MainSideBox">
                    <div class="MainSideBoxImg"><img src={img2} alt="bład ładowania..." /></div>
                    <div class="MainSideBoxText">
                        <b>Ponad 12900 GJ/rok</b>
                        <p>zaoszczędzonej energii cieplnej</p>
                </div>
            </div>
            <div class="MainSideBox">
                <div class="MainSideBoxImg"><img src={img3} alt="bład ładowania..."/></div>
                <div class="MainSideBoxText">
                    <b>Ponad 1720 t/rok</b>
                    <p>unikniętej emisji CO<sub>2</sub></p>
            </div>
        </div>
            </div >
        </div >
    );
}

export default MainSide;